import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6704dc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-warning default-border-radius mb-2 p-2 text-white" }
const _hoisted_2 = { class: "bg-secondary default-border-radius p-2 tw-items-dashed-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.asset.total',
        value: `${_ctx.formatWithMask(_ctx.getTotalAllAssets(), 2)}  €`,
        oneLine: true,
        "label-style": 'text-white',
        "value-style": _ctx.valueStyleTotal,
        "div-value-style": 'd-flex justify-between'
      }, null, 8, ["i18n-key", "value", "value-style"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assets, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: ""
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LabelAndValue, {
            "i18n-key": `Asset ${_ctx.formatWithMask(index + 1)}`,
            value: `${_ctx.formatWithMask(_ctx.totalPriceWithTax(index), 2)}  €`,
            oneLine: true,
            "label-style": 'text-primary',
            "value-style": 'text-primary',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemBar",
            "i18n-key": 'good.goodDetails.goodGeneralInfo.generalInformations',
            value: '',
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key"]),
          (item.config?.assetType === _ctx.assetBoat || item.config?.assetType === _ctx.assetEquipment)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 0,
                class: "itemsBar",
                "i18n-key": 'demand.asset.boat.type',
                value: _ctx.getType(item),
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'order.item.generalInfo.from',
            value: _ctx.getselectedAssetFrom(item),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.category',
            value: _ctx.getCategory(item),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.subCategory',
            value: _ctx.getSubCategory(item),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetEquipment)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 1,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.quantite',
                value: item.quantity,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.vehicle.brand',
            value: _ctx.getBrand(item.brand),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.vehicle.model',
            value: _ctx.getModel(item),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'summaryPanel.asset.description',
            value: item.description,
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetEquipment)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 2,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.priceTTC',
                value: `${_ctx.formatWithMask(item.totalAmountWTax.amount, 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (item.config?.assetType === _ctx.assetVehicule)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 3,
                class: "itemsBar",
                "i18n-key": 'demand.asset.matriculation',
                value: item.matriculation ? item.matriculation : '-',
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (item?.config?.assetType===_ctx.assetVehicule)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 4,
                class: "itemsBar",
                "i18n-key": 'demand.asset.matriculationDate',
                value: item.matriculationDate ? item.matriculationDate : '-',
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (item.config?.assetType === _ctx.assetBoat)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 5,
                class: "itemsBar",
                "i18n-key": 'demand.asset.boat.nbEngine',
                value: item.nbEngine ? item.nbEngine : '-',
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (item.config?.assetType === _ctx.assetBoat)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 6,
                class: "itemsBar",
                "i18n-key": 'demand.asset.boat.priceTtc',
                value: `${_ctx.formatWithMask(_ctx.totalPriceWithTax(index), 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          _createVNode(_component_LabelAndValue, {
            class: "itemBar",
            "i18n-key": 'demand.asset.deliveryDetails',
            value: '',
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.deliveryDate',
            value: item.assetDelivery && item.assetDelivery.deliveryDate ? item.assetDelivery.deliveryDate : '-',
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.supplier',
            value: _ctx.getSupplierName(item),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          _createVNode(_component_LabelAndValue, {
            class: "itemsBar",
            "i18n-key": 'demand.asset.address',
            value: _ctx.getSupplierAddress(item),
            oneLine: true,
            "label-style": 'text-dark',
            "div-value-style": 'd-flex justify-between'
          }, null, 8, ["i18n-key", "value"]),
          (item.config?.assetType === _ctx.assetBoat)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 7,
                class: "itemsBar",
                "i18n-key": 'demand.asset.boat.orderSigned',
                value: item.assetDelivery && item.assetDelivery.orderSigned ? item.assetDelivery.orderSigned ? _ctx.$t(`demand.asset.boat.orderSignedSituation.yes`) : _ctx.$t(`demand.asset.boat.orderSignedSituation.no`) : '-',
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (item.config?.assetType === _ctx.assetBoat)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 8,
                class: "itemsBar",
                "i18n-key": 'demand.asset.boat.orderSignedDate',
                value: item.assetDelivery && item.assetDelivery.orderSignatureDate ? item.assetDelivery.orderSignatureDate : '-' ,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetBoat)
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 9,
                class: "itemBar",
                "i18n-key": 'summaryPanel.asset.totalFrais',
                value: `${_ctx.formatWithMask(_ctx.proposalAssetItemsTotalWithTax(index), 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (_ctx.proposalAssetOptionItems.length && (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetBoat))
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 10,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.options',
                value: `${_ctx.formatWithMask(_ctx.proposalAssetOptionItemsTotalWithTax(index), 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (!_ctx.proposalAssetOptionItems.length && (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetBoat))
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 11,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.options',
                value: `${_ctx.formatWithMask(0, 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (_ctx.proposalAssetAccessoryItems.length && (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetBoat))
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 12,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.accessories',
                value: `${_ctx.formatWithMask(_ctx.proposalAssetAccessoryItemsTotalWithTax(index), 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (!_ctx.proposalAssetAccessoryItems.length && (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetBoat))
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 13,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.accessories',
                value: `${_ctx.formatWithMask(0, 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (_ctx.proposalAssetOtherItems.length && (item.config?.assetType === _ctx.assetVehicule || item.config?.assetType === _ctx.assetBoat))
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 14,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.fairsDivers',
                value: `${_ctx.formatWithMask(_ctx.proposalAssetOtherItemsTotalWithTax(index), 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true),
          (!_ctx.proposalAssetOtherItems.length && (item.config?.assetType === _ctx.assetVehicule  || item.config?.assetType === _ctx.assetBoat))
            ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                key: 15,
                class: "itemsBar",
                "i18n-key": 'summaryPanel.asset.fairsDivers',
                value: `${_ctx.formatWithMask(0, 2)}  €`,
                oneLine: true,
                "label-style": 'text-dark',
                "div-value-style": 'd-flex justify-between'
              }, null, 8, ["i18n-key", "value"]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ], 64))
}